import { render, staticRenderFns } from "./UserConfirmFormSection.vue?vue&type=template&id=563ac494&scoped=true"
import script from "./UserConfirmFormSection.vue?vue&type=script&lang=ts"
export * from "./UserConfirmFormSection.vue?vue&type=script&lang=ts"
import style0 from "./UserConfirmFormSection.vue?vue&type=style&index=0&id=563ac494&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563ac494",
  null
  
)

export default component.exports