import { render, staticRenderFns } from "./UserEditFormSection.vue?vue&type=template&id=15b27456&scoped=true"
import script from "./UserEditFormSection.vue?vue&type=script&lang=ts"
export * from "./UserEditFormSection.vue?vue&type=script&lang=ts"
import style0 from "./UserEditFormSection.vue?vue&type=style&index=0&id=15b27456&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b27456",
  null
  
)

export default component.exports