import { render, staticRenderFns } from "./ConfirmationSentSection.vue?vue&type=template&id=0ee94098&scoped=true"
import script from "./ConfirmationSentSection.vue?vue&type=script&lang=ts"
export * from "./ConfirmationSentSection.vue?vue&type=script&lang=ts"
import style0 from "./ConfirmationSentSection.vue?vue&type=style&index=0&id=0ee94098&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ee94098",
  null
  
)

export default component.exports