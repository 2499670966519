import { render, staticRenderFns } from "./PlanCardParts.vue?vue&type=template&id=702bd810&scoped=true"
import script from "./PlanCardParts.vue?vue&type=script&lang=ts"
export * from "./PlanCardParts.vue?vue&type=script&lang=ts"
import style0 from "./PlanCardParts.vue?vue&type=style&index=0&id=702bd810&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702bd810",
  null
  
)

export default component.exports